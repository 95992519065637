<!-- https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component -->
<template>
  <div>
    <UiPageHeader>
      <template #default>{{ $t('Reconcile') }}</template>
      <template #page-specific-content>
        <tooltip-select tip="_reconcile"></tooltip-select>
      </template>
    </UiPageHeader>
    <div class="tw-py-4 tw-px-6">
      <v-layout align-center justify-end class="pt-1" row wrap>
        <v-flex xs12 class="pa-1">
          <div class="text-xs-center pt-2">
            <pagination
              :query="q"
              :query-vars="vars"
              :page-pivot-fn="(item) => item.created"
              v-on:items="renderDataTable"
              :total-fn="(data) => data.transactions.txns.length"
              :items-fn="(data) => data.transactions.txns"
              ref="pagination"
              v-on:isLoading="isPaginationLoadingHandler"
            />
          </div>
          <v-data-table
            :items="transactions.txns"
            :headers="headers"
            item-key="id"
            :loading="isLoadingAll ? 'accent' : false"
            class="tw-border tw-border-neutral-100 tw-rounded-lg tw-overflow-hidden"
            hide-actions
            select-all
            v-model="selected"
            ref="txnTable"
          >
            <template slot="headers" slot-scope="props">
              <tr>
                <th>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-checkbox @change="selectAll"></v-checkbox>
                    </v-flex>
                    <v-flex offset-xs2 xs4 pt-1>
                      <a v-if="!isPaginationLoading" @click="refresh">
                        <v-icon color="success" class="spinner-icon">refresh</v-icon>
                      </a>

                      <v-progress-circular v-else indeterminate color="success"></v-progress-circular>
                    </v-flex>
                  </v-layout>
                </th>
                <th v-for="header in props.headers" :key="header.text" :class="['column text-xs-left']">
                  {{ header.text }}
                </th>
                <th>
                  <v-btn
                    small
                    color="success"
                    :disabled="selectedCount === 0"
                    @click="reconcileTransactions"
                    v-if="checkScope(scopeLiterals.TransactionReconcileUpdate)"
                    >{{ $tc('_reconcileSelectedTxn', selectedCount) }}</v-btn
                  >
                </th>
              </tr>
            </template>
            <template slot="items" slot-scope="tx">
              <tr :active="tx.selected">
                <!--<td>{{tx.item.amounts}}</td>-->
                <td>
                  <v-checkbox primary hide-details v-model="tx.selected"></v-checkbox>
                </td>
                <td class="text-xs-center">
                  <v-icon v-if="tx.item.categorizationStatus === 'Categorized'" color="green">done</v-icon>
                  <v-icon v-if="tx.item.categorizationStatus === 'Uncategorized'">close</v-icon>
                </td>
                <td class="text-xs-center">
                  <v-icon v-if="tx.item.reconciliationStatus === 'Reconciled'" color="green">done</v-icon>
                  <v-icon v-if="tx.item.reconciliationStatus === 'Unreconciled'">close</v-icon>
                </td>
                <td class="text-xs-left short-id">
                  <blockchain-explorer-link
                    :id="tx.item.id"
                    :amounts="tx.item.amounts"
                    :is-blockchain="tx.item.isBlockchain"
                  />
                </td>
                <td class="text-xs-left" style="white-space: nowrap">
                  {{ toPreferredDateTime(tx.item.created) }}
                </td>
                <td class="text-xs-left">
                  <div class="text-xs-left">
                    <ul style="list-style: none" class="pa-0" v-if="tx.item.wallet && tx.item.wallet.length > 0">
                      <li v-for="(item, index) in tx.item.wallet" v-bind:key="`w-${index}`">
                        {{ item && item.name ? item.name : 'Unknown Wallet' }}
                      </li>
                    </ul>
                    <div v-else>Unknown Wallet</div>
                    <!--{{ tx.item.wallet.name }}-->
                  </div>
                </td>
                <td class="text-xs-left tw-flex tw-gap-2 tw-flex-col" style="white-space: nowrap">
                  <div
                    class="tw-flex tw-gap-2"
                    v-for="(item, index) in tx.item.fullAmountSet"
                    v-bind:key="`f-${index}`"
                    :style="{ color: item.value < 0 ? 'red' : 'green' }"
                  >
                    <div xs9>{{ getCurrencySymbol(item.coin) }}</div>
                    <div xs1>{{ item.displayValue }}</div>
                  </div>
                </td>
                <td class="text-xs-left px-12" colspan="2">
                  <transaction-accounting-small-details
                    :txn="tx.item"
                    :categories="categories"
                    :contacts="contacts"
                    :invoices="invoices"
                  ></transaction-accounting-small-details>
                </td>
                <!--<td class="text-xs-left" :style='{"color": (tx.item.amount.value < 0 ? "red" : "green") }'>-->
                <!--<div style="float: left;">-->
                <!--<b>{{ getCurrencySymbol(tx.item.amount.coin) }}</b>-->
                <!--</div>-->
                <!--<div style="float: right">{{ tx.item.amount.displayValue }}</div>-->
                <!--</td>-->
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="10000" :top="true" multi-line vertical>
          {{ snackbarText }}
          <ul v-if="snackbarErrors.length">
            <li v-for="item in snackbarErrors" v-bind:key="item">{{ item }}</li>
          </ul>
          <v-btn flat @click="snackbar = false"> Close </v-btn>
        </v-snackbar>
      </v-layout>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import {
  CategoriesQuery,
  ContactsQuery,
  FilteredTransactionsQuery,
  InvoicesQuery,
} from '../../queries/transactionsPageQuery';
import Pagination from '../Pagination.vue';
import BlockchainExplorerLink from '../transactions/BlockchainExplorerLink';
import TransactionAccountingSmallDetails from '../transactions/TransactionAccountingSmallDetails';
import UiPageHeader from '../ui/UiPageHeader.vue';

export default {
  props: [],
  components: {
    Pagination,
    BlockchainExplorerLink,
    TransactionAccountingSmallDetails,
    UiPageHeader,
  },
  data() {
    return {
      q: FilteredTransactionsQuery,
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      snackbarErrors: [],
      headers: [
        {
          text: this.$t('_categorized'),
          align: 'center',
          value: 'categorizationStatus',
        },
        {
          text: this.$t('_reconciled'),
          align: 'center',
          value: 'reconciliationStatus',
        },
        {
          text: this.$t('_id'),
          align: 'left',
          value: 'id',
        },
        {
          text: this.$t('_date'),
          align: 'left',
          value: 'created',
        },
        {
          text: this.$tc('_wallet', 2),
          align: 'left',
          value: 'wallet',
        },
        {
          text: this.$t('_amount'),
          align: 'left',
          value: 'amount.value',
        },
        {
          text: this.$t('_accountingDetails'),
          align: 'right',
          value: '',
        },
      ],
      transactions: { txns: [], count: 0 },
      isLoading: 0,
      categories: [],
      contacts: [],
      selected: [],
      isPaginationLoading: 0,
      invoices: [],
      improperSyncErrorMessage:
        'The Accounting connection has not properly synced. Please ensure you have this configured correctly to allow transaction reconciliation.\nYou can do this by going to the Administration section on the left hand toolbar, then selection Accounting Connections.',
    };
  },
  mounted() {
    this.refresh();
  },
  apollo: {
    invoices: {
      query: InvoicesQuery,
      pollInterval: 20000,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      fetchPolicy: 'network-only',
      loadingKey: 'isLoading',
    },
    categories: {
      query: CategoriesQuery,
      pollInterval: 20000,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      fetchPolicy: 'network-only',
      loadingKey: 'isLoading',
    },
    contacts: {
      query: ContactsQuery,
      pollInterval: 20000,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      fetchPolicy: 'network-only',
      loadingKey: 'isLoading',
    },
  },
  methods: {
    selectAll(value) {
      if (value) {
        this.selected = this.transactions.txns;
      } else {
        this.selected = [];
      }
    },
    isPaginationLoadingHandler: function (isLoading) {
      this.isPaginationLoading = isLoading;
    },
    reconcileTransactions() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        transactionIds: this.selected.map((m) => m.id),
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $transactionIds: [String]!) {
              reconcileTransactions(orgId: $orgId, transactionIds: $transactionIds) {
                succeeded
                errors
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then((res) => {
          // if (res.errors || res.data.reconcileTransactions[0].errors) {
          const erroredTxns = res.data.reconcileTransactions.filter((m) => {
            return m.errors && m.errors.length > 0;
          });

          if ((res.errors && res.errors.length > 0) || erroredTxns.length > 0) {
            const errors = [];
            if (res.errors && res.errors.length > 0) {
              errors.push(
                ...res.errors.map((e) => {
                  if (e.includes('invalid_grant')) return this.improperSyncErrorMessage;
                  return e;
                })
              );
            }

            if (erroredTxns && erroredTxns.length > 0) {
              for (const e of erroredTxns) {
                errors.push(...e.errors);
              }
            }

            this.snackbarText = this.$tc('_reconciliationFailure', this.selectedCount) + ': ';
            this.snackbarErrors = res.data.reconcileTransactions[0].errors;
            this.snackbarColor = 'error';
            this.snackbar = true;
          } else if (res.data.reconcileTransactions.length > 0 && res.data.reconcileTransactions[0].succeeded) {
            this.snackbarText = this.$tc('_reconciliationSuccess', this.selectedCount);
            this.snackbarErrors = [];
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            this.snackbarText = this.$tc('_reconciliationFailure', this.selectedCount);
            this.snackbarErrors = [];
            this.snackbarColor = 'error';
            this.snackbar = true;
          }
          this.selected = [];
          this.refresh();
        });
    },
    toggleAll() {
      const anySelected = this.transactions.txns.find((m) => m.selected);
      if (anySelected) {
        this.transactions.txns.forEach((m) => {
          this.$set(m, 'selected', false);
          // m.selected = false;
        });
      } else {
        this.transactions.txns.forEach((m) => {
          this.$set(m, 'selected', true);
        });
      }
    },
    async refresh() {
      this.$refs.pagination.refetch();
    },
    renderDataTable: function (data) {
      this.transactions = data.transactions;
    },
    getCurrencySymbol(coin) {
      if (coin === 'BTC') {
        return '฿';
      } else if (coin === 'ETH') {
        return 'Ξ';
      } else if (coin === 'EOS') {
        return 'EOS';
      } else {
        return coin;
      }
    },
    // toLocalDateTime(timestamp) {
    //   return moment.unix(timestamp).tz(moment.tz.guess()).format('lll z');
    //   // return moment
    //   //   .unix(timestamp)
    //   //   .local()
    //   //   .format('lll');
    //   // return moment(timestamp, "X")
    //   //   .utc()
    //   //   .format("lll");
    // },
  },
  computed: {
    isLoadingAll: function () {
      return this.isLoading + this.isPaginationLoading > 0;
    },
    vars() {
      if (this.$store.state.currentOrg.taxConfig.capitalizeTradingFees) {
        return {
          orgId: this.$store.state.currentOrg.id,
          transactionFilter: {
            categorizationFilter: 'Categorized',
            reconciliationFilter: 'Unreconciled',
            accountingDetails: {
              isTrade: false,
            },
          },
        };
      } else {
        return {
          orgId: this.$store.state.currentOrg.id,
          transactionFilter: {
            categorizationFilter: 'Categorized',
            reconciliationFilter: 'Unreconciled',
          },
        };
      }
    },
    selectedCount() {
      if (this.selected && this.selected.length) {
        return this.selected.length;
      } else {
        return 0;
      }
    },
  },
};
</script>
